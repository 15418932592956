<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8 order-xl-1">
          <profile-form></profile-form>
        </div>
        <div class="col-xl-4 order-xl-2">
          <profile-user-card></profile-user-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileForm from "@/views/Store/User/ProfileForm";
import ProfileUserCard from "@/views/Store/User/ProfileUserCard";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

export default {
  components: {
    BreadcrumbHeader,
    ProfileForm,
    ProfileUserCard,
  },
  computed: {
    breadcrumb() {
      return [
        {title: 'Użytkownik', link: {name: 'user'}},
        {title: 'Profil'}
      ];
    },
  },
};
</script>
