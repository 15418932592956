<template>
  <div class="card card-profile">
    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <avatar :username="fullName" :size="120" :lighten="90"></avatar>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div>
              <span class="heading">{{ user.roles.join(', ') }}</span>
              <span class="description">Rola</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <h5 class="h3">
          {{ fullName }}
        </h5>
        <div class="h5 font-weight-300">
          {{ user.username }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Avatar from "@/components/Avatar";

const { mapGetters } = createNamespacedHelpers('Auth');

export default {
  name: 'ProfileUserCard',
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters({
      user: 'authUser',
    }),
    fullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
};
</script>

<style>
.card-profile div div .vue-avatar--wrapper {
  margin-top: 50px;
}
</style>
