<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Profil</h3>
      </div>
    </div>

    <form @submit.prevent="updateProfile">
      <h6 class="heading-small text-muted mb-4">Informacje o użytkowniku</h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <base-input type="text"
                        label="Imię"
                        placeholder="Imię"
                        v-model="user.firstName"
                        disabled
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input type="text"
                        label="Nazwisko"
                        placeholder="Nazwisko"
                        v-model="user.lastName"
                        disabled
            >
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <base-input type="text"
                        prepend-icon="fas fa-user"
                        label="Nazwa użytkownika"
                        placeholder="Nazwa użytkownika"
                        v-model="user.username"
                        disabled
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input type="email"
                        prepend-icon="fas fa-envelope"
                        label="Adres email"
                        placeholder="Email"
                        v-model="user.email"
                        disabled
            >
            </base-input>
          </div>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers('Auth');

export default {
  name: 'ProfileForm',
  computed: {
    ...mapGetters({
      user: 'authUser',
    }),
  },
  methods: {
    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user));
    }
  }
};
</script>
